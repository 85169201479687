@import "~bf-solid/_lib/solid-helpers/variables";
@import "~bf-solid/_lib/solid-helpers/mixins";

/**
  * import various adlib styles needed for spotlight ads, as well as a basic
  *   set of solid polyfills that are used by those ads
  */
@import "./solid-polyfill";
@import "~@buzzfeed/adlib/core/sass/variables";
@import "~@buzzfeed/adlib/core/sass/base";
@import "~@buzzfeed/adlib/components/awareness/awareness";
@import "~@buzzfeed/adlib/components/toolbar/toolbar";
@import "~@buzzfeed/adlib/components/promo-inline/promo-inline";

.Ad--awareness,
.Ad--awareness-bp {
  z-index: 999;
  background: white;

  .ad-awareness {
    &:not(.ad--rendered),
    &.ad-flexible--programmatic {
      padding-top: $space-1;
      padding-bottom: $space-1;

      @include solid-breakpoint("md") {
        padding-top: $space-2;
        padding-bottom: $space-2;
      }
    }
  }
}

.Ad--awareness--sticky {
  @extend %awareness-mobile-sticky;

  // override adlib's lower z-index
  &.sticky {
    z-index: 999;
  }
}

.Ad--promo1-wide {
  margin-left: calc(-50vw + 50%);
  margin-right: calc(-50vw + 50%);
}

// prevent extra padding below tasty footer
.ad-scroll-sub--enabled {
  padding-bottom: 0;
}

.Ad {
  // large breakpoint (mainly awareness/spotlight ad adjustments)
  @media (min-width: $solid-md) {
    .ad-awareness.ad-flexible.ad-flexible--programmatic {
      background: none;
    }
    // medium and large breakpoint styling for spotlight awareness ads
    .ad-flexible--spotlight.ad-awareness {
      &:not(.ad-spotlight--jumbo) {
        .awareness-inner {
          max-width: 73rem;
        }
      }
      .ad-awareness-content {
        padding-left: 3rem;
        padding-right: 3rem;
      }
      .ad-awareness-headline {
        margin-left: .5rem;
      }
      .ad-awareness-dek {
        padding-top: .5rem;
        padding-bottom: .5rem;
      }
      .ad-button {
        font-size: 16px;
        font-weight: 400;
      }
      .ad-disclosure {
        margin-left: 3rem;
        margin-right: 0;
        padding: 0;
      }
      .ad-disclosure-text {
        font-size: 12px;
        font-weight: 600;
        text-transform: uppercase;
      }
      .ad-advertiser-link {
        img {
          object-fit: contain;
          object-position: center;
        }
      }

      // desktop - non-sticky (video and image)
      &:not(.sticky--show):not(.sticky--fixed):not(.ad-spotlight--jumbo) {
        // adding padding on no sticky so not fully flush with side on smaller breakpoints
        padding-left: 1rem;
        padding-right: 1rem;
      }
      // desktop - non-sticky (video)
      &.ad-flexible--media-video:not(.sticky--show):not(.sticky--fixed) {
        // for proper resizing of thumbnail because autoplay does not happen on video ads
        .awareness-inner {
          overflow: hidden;
        }
        &.sticky .ad-slot-media {
          transition: height 0.5s;
          height: 251px;
        }
      }
      // desktop - sticky (video and image)
      &.sticky--show.sticky--fixed {
        top: -2px;
        max-height: 120px;
        height: 120px;
        .awareness-inner {
          max-width: 72.5rem;
          max-height: initial;
          height: 100%;
          padding-right: 1.5rem;
          padding-top: 1rem;
          padding-bottom: 1rem;
        }
        // offset media to not be below tasty logo directly
        .ad-slot-media {
          margin-left: 165px;
          img {
            width: initial;
          }
        }
        .ad-awareness-content {
          padding-left: 1.5rem;
          padding-right: 0;
        }
        .ad-disclosure {
          flex-direction: row;
          padding: 0;
        }
        .ad-disclosure-text {
          margin-right: 2rem;
        }
        .ad-advertiser-link {
          height: 60px;
        }
        .ad-awareness__dismiss--x {
          top: 0;
          right: 0;
          padding-right: 0.25rem;
          padding-top: 0.25rem;
        }
      }

      // additional overrides for tablet size (medium breakpoint only)
      @media (max-width: $solid-lg) {
        // tablet - non-sticky (video and image shared)
        &.ad-flexible--aspect-fluid:not(.sticky--show):not(.sticky--fixed) {
          .ad-advertiser-link {
            max-width: 100px;
          }
          .ad-disclosure {
            margin-left: 1.5rem;
          }
        }
        // tablet - non-sticky (video)
        &.ad-flexible--media-video:not(.sticky--show):not(.sticky--fixed):not(.ad-spotlight--jumbo) {
          .ad-slot-media {
            width: 330px;
            position: relative;
            align-items: center;
            .ad-slot {
              position: relative;
            }
            // for video - show blackbars
            background: #000;

            .ad-slot-media-inner {
              display: flex;
              justify-content: center;
              align-items: center;
              position: relative;
            }
          }
          &.ad-flexible--aspect-16\:9 {
            .ad-slot {
              margin-left: 1rem;
            }
            .ad-slot, .ad-slot [id^="google_ads_iframe"] {
              height: 186px !important;
            }
          }
          &.ad-flexible--aspect-4\:3 {
            .ad-slot, .ad-slot [id^="google_ads_iframe"] {
              // ends up being full height
              height: 248px !important;
            }
          }
          &.ad-flexible--aspect-1\:1 {
            // for 1-to-1, make width smaller
            .ad-slot-media {
              width: 248px;
            }
            .ad-slot, .ad-slot [id^="google_ads_iframe"] {
              height: 248px !important;
            }
          }
        }
        // tablet - non-sticky (image)
        &.ad-flexible--aspect-fluid:not(.sticky--show):not(.sticky--fixed) {
          .ad-slot-media img {
            padding-top: 2rem;
            padding-bottom: 2rem;
          }
        }
        // tablet - sticky (video and image)
        &.sticky--show.sticky--fixed.sticky {
          .ad-slot-media {
            margin-left: 155px;
          }
          .ad-awareness-content {
            padding-left: 1rem;
            padding-right: 0;
          }
          .ad-advertiser-link {
            max-width: 110px;
          }
          .ad-awareness-dek {
            span {
              display: inline-block;
            }
            font-size: 0.875rem;
            line-height: 1.45;
          }
        }
      }
    }
  }
  // Mobile spotlight ads

  @media (max-width: 51.999rem) {
    &.Ad--awareness {
      /**
        * Because spotlight uses a different type on mobile (promo-inline vs awareness), hide
        *   awareness if user resizes a window down, as the other spot will handle it
        */
      .ad-flexible--spotlight {
        display: none !important;
      }
    }
    .ad-flexible--spotlight.ad-promo-inline {
      max-width: 100%;
      left: 0;
    }
    // for proper resizing of thumbnail because autoplay does not happen on video ads
    .ad-flexible--spotlight.ad-flexible--media-video.ad-promo-inline {
      .ad-slot {
        overflow: hidden;
      }
      &.ad--loaded .ad-slot iframe {
        transition: width 2s, margin-left 2s;
        margin-left: -0.25%;
        width: 100.5%;
      }
    }
  }
  // Mobile spotlight ads (using promo1-bp / promo-inline logic)
  .ad-promo-inline {
    background: none;
    margin-bottom: 0rem;
  }
  &.Ad--promo1-bp {
    // additional styling for spotlight mobile inline ads (but only if not hidden)
    .ad-wireframe-wrapper:not(.ad-wireframe-wrapper--hidden) {
      margin-top: 1rem;
      .ad-promo-inline.ad-flexible--spotlight {
        .awareness-inner {
          margin-bottom: 1.5rem;
        }
      }
      .ad-promo-inline {
        @media (min-width: 332px) {
          padding: 0 1rem;
        }
      }
      // reorder media versus ad copy
      .awareness-inner {
        display: flex;
        flex-direction: column;
        .ad-slot-media {
          order: 2;
        }
      }
    }
  }
}

.compilation-page {
  .Ad {
    .ad-promo-inline {
      margin-bottom: 1rem;
    }
  }
}

.article-page {
  .ad-wireframe-wrapper--inline {
    position: relative;

    @include solid-breakpoint("sm") {
      left: 50%;
      transform: translateX(-50%);
    }
    .ad-flexible--empty .ad-slot-invisible {
      display: none;
    }
  }
  .ad-promo-inline .ad-slot {
    min-width: initial;
  }
}

.Ad {
  .ad-ex .ad__disclosure--ex {
    font-weight: 600;
    letter-spacing: .8px;
  }
}

// override min wireframe height because it won't adjust to the actual ad size if a shorter height ad loads
// (since `ad-wireframe-wrapper`s were designed for semi-sticky ads)
.ad-wireframe-wrapper--awareness[data-wireframe-height="250"] {
  min-height: 90px;
  .ad-awareness:not(.ad--loaded) .ad-slot {
    height: 90px;
  }
}

.ad-wrapper--mobile {
  display: block;
  @include solid-breakpoint("md") {
    display: none;
  }
}

.ad-wrapper--desktop {
  display: none;
  @include solid-breakpoint("md") {
    display: block;
  }
}

.ad-wrapper--below-lg {
  display: block;
  @include solid-breakpoint("lg") {
    display: none;
  }
}

.ad-wrapper--sm {
  display: none;
  @include solid-breakpoint("sm") {
    display: block;
  }
  @include solid-breakpoint("md") {
    display: none;
  }
}

.ad-wrapper--md {
  display: none;
  @include solid-breakpoint("md") {
    display: block;
  }
  @include solid-breakpoint("lg") {
    display: none;
  }
}

.ad-wrapper--lg {
  display: none;
  @include solid-breakpoint("lg") {
    display: block;
  }
}

.ad-wrapper--mobile,
.ad-wrapper--xs,
.ad-wrapper--sm,
.ad-wrapper--lg {
  min-height: 300px;
}

.ad-wrapper--md {
  min-height: 90px;
}

.ad-wrapper--below-lg {
  min-height: 300px;
  @include solid-breakpoint("md") {
    min-height: 130px;
  }
}

.ad-wrapper--subbuzzes {
  min-height: 300px;
  margin-bottom: $space-3;
  @media (min-width: $solid-md) and (max-width: $solid-lg), (min-width: 1088px) {
    min-height: 130px;
  }
}

.ad-wrapper--sidebar {
  min-height: 300px;
  display: none;
  @include solid-breakpoint("lg") {
    display: block;
  }
}

.placeholderAd {
  min-height: 74px;
  @include solid-breakpoint("md") {
    min-height: 122px;
  }

  @include solid-breakpoint("lg") {
    min-height: 122px;
  }
}

.placeholderAd--video-recipe {
  @include solid-breakpoint("lg") {
    min-height: 122px;
  }
  @include solid-breakpoint("md") {
    min-height: 122px;
  }
}
